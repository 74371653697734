var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$account.isFullAdmin || this.$account.permissions.canViewUserEmail === true)?_c('div',{staticClass:"messages"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.messagesList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id.slice(-5))+" ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message.subject)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.delivery.state)+" ")]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.delivery.endTime))+" ")]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.delivery.startTime))+" ")]}}],null,true)})],1):_c('div',{staticClass:"messages"},[_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"white"}},[_vm._v(" You are not allowed on this page... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }